/* between x pro and tablet */
@media only screen and (max-width: 425px) {
	.tedkvn-intro-header {
		font-size: 1.5rem;
		line-height: 2rem;
		letter-spacing: 0.1rem;
	}

	.tedkvn-myjourney-header,
	.tedkvn-background-header,
	.tedkvn-aboutme-header {
		font-size: 2rem;
	}
}
