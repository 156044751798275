.tedkvn-center {
	display: flex;
	justify-content: center;
	align-items: center;
}

.tedkvn-center-left {
	display: flex;
	justify-content: left;
	align-items: center;
}

.tedkvn-center-right {
	display: flex;
	justify-content: right;
	align-items: center;
}

.tedkvn-myjourney-journey-textTrim {
	text-overflow: ellipsis;   /* IE, Safari (WebKit) */
    overflow:hidden;              /* don't show excess chars */
    white-space:nowrap;           /* force single line */
    width: 300px;  /*This property playing a major role just showing the first 2 letter*/
}
