#myjourney {
	color: #fffae7;
}

.tedkvn-myjourney-header {
	font-weight: 600;
	font-size: 2.5rem;
	line-height: 125%;
	letter-spacing: 0.02rem;
}
