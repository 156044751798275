#aboutme {
	color: #fffae7;
}

.tedkvn-aboutme-header {
	font-weight: 600;
	font-size: 2.5rem;
	line-height: 125%;
	letter-spacing: 0.02rem;
}

.tedkvn-aboutme-desc {
	font-weight: 400;
	font-size: 1rem;
	line-height: 200%;
}
